import * as React from "react"
import Footer from "../components/footer";
import Page from "../components/page";

export default function TemplatePage() {
    return (
        <Page>

        </Page>
    )
};